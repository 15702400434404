import Axios from "axios";
import md5 from 'js-md5';

// 定义axios配置
const http = Axios.create({
  baseURL: '', // api的base_url
  withCredentials: true, // 开启跨域身份凭证
  method: "post",
  headers: {
    "app_auth_token": "311dd53623facdcbb5667f2797561998",
    "app_id": "200655204998",
    "charset": "UTF-8",
    "format": "JSON",
    // "method": config.headers['method'],
    "sign_type": "MD5",
    "timestamp": Date.now(),
    "version": "1.0"
  },
  timeout: 5000 // request timeout
});

// 设置全局的请求次数，请求的间隙，用于自动再次请求
http.defaults.retry = 2;
http.defaults.retryDelay = 1000;

// 请求拦截器
http.interceptors.request.use(
  function (config) {
    let data = config.data
    config.headers["biz_content"] = data
    if(data != undefined && Object.keys(data).length !== 0){
      let sorted_headers = Object.entries(data).map((item) => {
        if(typeof(item[1]) == 'object') {
          return item[0] + "=" + JSON.stringify(item[1])
        } else {
          return item[0] + "=" + item[1]
        }
      })
      config.headers["sign"] = md5(sorted_headers.join("&")+ "v1zg66943TnsRM6R247RM48i3m44Mxmw")
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  function (res) {
    return res;
  },
  function (err) {
    let config = err.config;
    let errres = err.response;
    let err_type = errres ? errres.status : 0;
    // 收集错误信息
    switch (err_type) {
      case 400:
        err.message = "请求无效";
        break;

      case 401:
        err.message = "由于长时间未操作，登录已超时，请重新登录";
        break;

      case 403:
        err.message = "拒绝访问";
        break;

      case 404:
        err.message = `请求地址出错: ${errres.config.url}`;
        break;

      case 405:
        err.message = `未授权`;
        break;

      case 408:
        err.message = "请求超时";
        break;

      case 500:
        err.message = "服务器内部错误";
        break;

      case 501:
        err.message = "服务未实现";
        break;

      case 502:
        err.message = "网关错误";
        break;

      case 503:
        err.message = "服务不可用";
        break;

      case 504:
        err.message = "网关超时";
        break;

      case 505:
        err.message = "HTTP版本不受支持";
        break;

      default:
        err.message = "网络波动，请重试";
    }

    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(err);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    let backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return http(config);
    });
  }
);

export default http;